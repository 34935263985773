<template>
  <div class="main-view">
    <el-radio-group
      v-model="table.params.status"
      class="tabsWrap"
      @change="handleTabs"
    >
      <el-radio-button :label="1"
        >在架中({{ table.publish_total }})</el-radio-button
      >
      <el-radio-button :label="2"
        >已下架({{ table.un_publish_total }})</el-radio-button
      >
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="商品名称" prop="good_name">
          <el-input
            size="mini"
            v-model.trim="table.params.good_name"
            placeholder="请输入商品名称"
            clearable
            id="good_name"
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品分类" prop="cate_id">
          <el-select
            v-model="table.params.cate_id"
            filterable
            placeholder="请选择"
            id="cate_id"
            clearable
            @change="onSearch"
          >
            <el-option
              v-for="item in cateOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单分类" prop="order_cate_arr">
          <el-cascader
            v-model="table.params.order_cate_arr"
            filterable
            placeholder="请选择"
            id="order_cate_arr"
            @change="changeOrderCateId"
            @focus="getOrderCateSel"
            :options="orderCateArr"
            :props="{
              children: 'child',
              label: 'name',
              value: 'id',
              checkStrictly: true,
            }"
            clearable
          ></el-cascader>
        </el-form-item>
        <!-- 选客户名称，实际要传分组id给接口 -->
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select
            v-model="table.params.merchant_id"
            filterable
            placeholder="请选择"
            id="group_id"
            clearable
            @change="onSearch"
          >
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上架时间" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            id="date"
            class="selDaterangeWidth"
            @change="onSearch"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="下架时间"
          prop="down_time"
          v-if="table.params.status == 2"
        >
          <el-date-picker
            v-model="table.params.down_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            id="down_time"
            class="selDaterangeWidth"
            @change="onSearch"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="供应商/采购员" prop="bind_id">
          <el-select
            v-model="table.params.bind_id"
            filterable
            id="bind_id"
            clearable
            @change="onSearch"
          >
            <el-option
              v-for="item in supplierArr"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标准名称" prop="norm_id">
          <el-select
            v-model="table.params.norm_id"
            filterable
            remote
            clearable
            placeholder="请输入"
            :remote-method="remoteMethod"
            @focus="focusNocus"
            :loading="selLoading"
            value-key="id"
            style="width: 100%"
            @change="onSearch"
          >
            <el-option
              v-for="item in normArr"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联标名" prop="is_norm">
          <el-select
            v-model="table.params.is_norm"
            placeholder="请选择"
            clearable
            @change="onSearch"
          >
            <el-option label="已关联" :value="1"></el-option>
            <el-option label="未关联" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品图片" prop="is_image">
          <el-select
            v-model="table.params.is_image"
            placeholder="请选择"
            clearable
            @change="onSearch"
          >
            <el-option label="有" :value="1"></el-option>
            <el-option label="无" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否置顶" prop="is_top">
          <el-select
            v-model="table.params.is_top"
            placeholder="请选择"
            clearable
            @change="onSearch"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-right" @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button
          :size="subUnitSize"
          type="primary"
          icon="el-icon-plus"
          @click="showEdit"
          >创建商品</el-button
        >
        <el-dropdown @command="handleCommand($event)" style="margin:0 10px;">
          <el-button type="primary" :size="subUnitSize">
            更多操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="allexport" icon="el-icon-finished">全部导出</el-dropdown-item>
            <el-dropdown-item command="option" :icon="table.params.status == 1 ? 'el-icon-sort-down' : 'el-icon-sort-up'">{{ table.params.status == 1 ? "批量下架" : "批量上架" }}</el-dropdown-item>
            <el-dropdown-item command="price" icon="el-icon-sort">批量调价</el-dropdown-item>
            <el-dropdown-item command="enquiry" icon="el-icon-download">导出询价表</el-dropdown-item>
            <el-dropdown-item command="remover" icon="el-icon-delete" v-if="is_sys == 1">批量删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          :size="subUnitSize"
          type="primary"
          plain
          @click="plexportDataGoods('/admin/goods/export')"
          >批量导出</el-button
        >
        <el-button
          :size="subUnitSize"
          type="primary"
          plain
          @click="handleImport(1)"
          >导入商品</el-button
        >
        <el-button
          :size="subUnitSize"
          type="primary"
          plain
          @click="handleImport(2)"
          >导入询价表</el-button
        >
        <el-button
          :size="subUnitSize"
          type="primary"
          plain
          @click="handleImportImg"
          >导入图片</el-button
        >
        <el-button
          :size="subUnitSize"
          type="primary"
          plain
          @click="handlesupplierBatch"
          >批量修改供应商</el-button
        >
        <el-checkbox
          v-model="table.params.is_sold_out"
          style="margin-left: 10px"
          @change="onSearch"
          >只看售罄</el-checkbox
        >
        <el-checkbox
          v-model="table.params.is_spec"
          style="margin-left: 0px"
          @change="onSearch"
          >未设规格</el-checkbox
        >
        <el-checkbox
          v-model="table.params.is_price"
          style="margin-left: 0px"
          @change="onSearch"
          >未设报价</el-checkbox
        >
      </div>
    </div>
    <div class="curTableWrap" ref="main">
      <el-table
        v-if="refreshTable"
        :data="table.data"
        :height="height"
        border
        size="mini"
        ref="refTable"
        :default-expand-all="isExpandAll"
        header-align="center"
        align="center"
        v-loading="table.loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)"
        :header-cell-style="{ background: '#f5f5f5' }"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
        row-key="id"
        style="width: 100%"
      >
        <el-table-column type="expand" style="padding: 0 !important" width="60">
          <template slot="header">
            <el-tooltip
              effect="dark"
              :content="isExpandAll ? `收起规格` : `展开规格`"
              placement="bottom"
            >
              <i
                class="el-icon-menu"
                color="#2cb167"
                style="color: #2cb167"
                @click="toggleExpand"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            <div class="demo-table-expand">
              <div
                class="specwrap"
                v-for="(item, index) in row.spec"
                :key="index"
              >
                <div
                  class="specitem"
                  style="display: flex; justify-content: space-between"
                >
                  <div
                    style="
                      width: 200px !important;
                      border-right: solid 1px #eee;
                    "
                  >
                    规格{{ index + 1 }}：
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      flex: 1;
                    "
                  >
                    <div
                      style="
                        width: 180px;
                        border-right: solid 1px #eee;
                        text-align: center;
                      "
                    >
                      {{ item.unit_name }}
                    </div>
                    <div class="item" style="padding: 0 1px">
                      {{ item.name }}
                    </div>
                    <div class="item">报价数：{{ item.group.length }}</div>
                    <div class="item">
                      <div v-if="!!table.params.group_id">
                        售价：
                        <span v-if="!!item.price">¥</span>
                        {{ item.price || "--" }}
                      </div>
                      <div style="display: block" v-else>
                        <div v-if="!!item.group_price">
                          售价：
                          <span>¥{{ item.group_price }}</span>
                          <!-- group_price_count 大于1就加“起”字  -->
                          <span
                            v-if="item.group_price_count > 1"
                            style="font-size: 12px"
                          >
                            起</span
                          >
                        </div>
                        <div v-else>售价：--</div>
                      </div>
                    </div>
                    <div class="item">{{ item.create_time }}</div>
                    <div style="width: 70px">&nbsp;</div>
                    <div class="item">
                      <span
                        class="textBtn"
                        style="margin-right: 10px"
                        v-if="item.group.length > 0"
                        @click="handleGroups(row, index)"
                        >查看报价</span
                      >
                      <span class="textBtn" @click="handleDelSpec(item)"
                        >删除规格</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="selection" width="60"> </el-table-column>
        <el-table-column label="序号" type="index" width="80">
        </el-table-column>
        <el-table-column label="商品信息" prop="name" width="180">
          <template slot-scope="{ row }">
            <div style="display: flex; align-items: center">
              <el-image
                v-if="row.pics.length > 0"
                style="width: 40px; height: 40px"
                :src="row.pics[0]"
                fit="cover"
                :preview-src-list="row.pics"
              ></el-image>
              <el-image
                v-else
                style="width: 40px; height: 40px"
                :src="require('@/assets/img/logo-icon.jpeg')"
                fit="cover"
                :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"
              ></el-image>
              <div>
                <div style="margin-left: 10px">{{ row.good_name }}</div>
                <div style="margin-left: 10px">{{ row.code }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="下单分类" prop="order_cate_name" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.order_cate_name }}</span>
            <span v-if="!!row.order_tow_name"> / {{ row.order_tow_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="供应商/采购员" prop="bind_arr" align="center">
          <template slot-scope="{ row }">
            <template v-if="row.bind_arr.length > 0">
              <span v-for="(item, bindex) in row.bind_arr" :key="bindex">
                <span type="text">【{{ item }}】</span>
              </span>
              <el-button type="text" @click="handlesupplier(row)"
                ><i class="el-icon-edit"></i
              ></el-button>
            </template>
            <template v-else>
              <el-button type="text" @click="handlesupplier(row)"
                ><i class="el-icon-edit"></i
              ></el-button>
            </template>
          </template>
        </el-table-column>
        <!-- 隐藏标准名称 -->
        <!-- <el-table-column
          label="标准名称"
          prop="norm_name"
          align="center">
          <template slot-scope="{row,$index}">
            <el-popover
              :ref="`popoverDom+${$index}`"
              placement="bottom"
              width="250"
              trigger="click">
              <el-form
                class="staff-from"
                :model='form.data'
                center
              >
                <el-form-item label="商品标名" prop="norm_id">
                  <el-select
                    v-model="norm_id"
                    filterable
                    remote
                    placeholder="请输入"
                    :remote-method="remoteMethod"
                    :loading="selLoading"
                    value-key="id"
                    style="width:100%;"
                    @focus="focusNocus">
                    <el-option v-for="item in normArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-row type="flex" justify="center">
                <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="handleNorm(row,$index)">确定</el-button>
              </el-row>
              <el-button slot="reference" type="text">{{row.norm_name}}<i class="el-icon-edit"></i></el-button>
            </el-popover>
          </template>
        </el-table-column> -->
        <el-table-column label="已售" prop="sales" align="center">
        </el-table-column>
        <el-table-column label="创建时间" prop="create_time" align="center">
        </el-table-column>
        <el-table-column label="" prop="is_top" align="center" width="70px">
          <template slot="header">
            <el-tooltip
              content="置顶排序的商品将在用户端首页优先展示，最后操作置顶将排在第一"
              placement="top"
            >
              <span
                >置顶<i
                  class="el-icon-warning"
                  style="color: #e6a23c; margin-left: 5px"
                ></i
              ></span>
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.is_top"
              :active-value="1"
              :inactive-value="0"
              @change="handleStatus(row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="active" align="center">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              style="font-size: 18px"
              @click="showEdit(row)"
              icon="el-icon-edit"
              title="编辑商品"
            ></el-button>
            <el-button
              type="text"
              style="font-size: 18px"
              @click="view(row)"
              :icon="row.status == 1 ? 'el-icon-download' : 'el-icon-upload2'"
              :title="row.status == 1 ? '下架商品' : '上架商品'"
            ></el-button>
            <el-button
              v-if="is_sys == 1"
              type="text"
              style="font-size: 18px"
              @click="remove(row)"
              icon="el-icon-delete"
              title="删除商品"
            ></el-button>
            <el-button
              type="text"
              style="font-size: 18px"
              @click="showCode(row)"
              title="查看溯源码"
            >
              <el-image
                style="
                  width: 18px;
                  height: 18px;
                  top: 3px;
                  display: inline-block;
                "
                :src="require('@/assets/img/code-icon.png')"
                fit="cover"
              ></el-image>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination__wrap">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="table.params.page"
          :page-size="table.params.count"
          :total="table.total"
          :page-sizes="[10, 20, 50, 100, 150]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 查看报价 -->
    <el-dialog title="查看报价" width="800px" :visible.sync="dialogFormVisible">
      <el-form :model="form" size="small">
        <div style="margin-bottom: 20px">
          <span>商品名称：{{ form.data.good_name }}</span>
          <span style="margin-left: 100px"
            >商品分类：{{ form.data.cate_name }}</span
          >
        </div>
        <el-table
          :data="groupsData"
          style="width: 100%"
          :header-cell-style="{ background: '#f5f5f5' }"
          border
        >
          <el-table-column type="index" label="ID" width="60">
          </el-table-column>
          <el-table-column prop="group_name" label="客户分组名称">
          </el-table-column>
          <el-table-column prop="object" label="参考对象"> </el-table-column>
          <el-table-column prop="price" label="参考价">
            <template slot-scope="{ row }">
              <span v-if="!!row.price">¥{{ row.price }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="rate" label="折扣率">
            <template slot-scope="{ row }">
              <span v-if="!!row.rate">{{ row.rate }}%</span>
            </template>
          </el-table-column>
          <el-table-column prop="sell_price" label="售价">
            <template slot-scope="{ row }">
              <span v-if="!!row.sell_price">¥{{ row.sell_price }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="action" label="操作">
            <template slot-scope="{ row }">
              <span
                :class="
                  row.sold_out == 1
                    ? 'sold_out redColor'
                    : 'sold_out greenColor'
                "
                @click="handSoldout(row)"
                >{{ row.sold_out == 1 ? "售罄" : "有货" }}</span
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-dialog>

    <!-- 导入商品，更新分组价 -->
    <el-dialog
      :title="currImportType == 1 ? '导入商品' : '更新询价表'"
      :visible.sync="dialogVisible"
      width="460px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        ref="elFormDom"
        :rules="form.rules"
        label-position="top"
      >
        <el-form-item>
          <el-button
            type="primary"
            plain
            @click="exportData('/admin/goods/download')"
            v-if="currImportType == 1"
            >下载导入样表</el-button
          >
          <el-button
            type="primary"
            plain
            @click="exportData('/admin/goods/downloadUpdate')"
            v-if="currImportType == 2"
            >下载询价样表</el-button
          >
        </el-form-item>
        <template v-if="currImportType == 2">
          <!-- 选客户名称，实际要传分组id给接口 -->
          <el-form-item label="客户分组" prop="group_id">
            <el-select v-model="form.group_id" filterable id="group_id">
              <el-option
                v-for="item in clientGroupArr"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="type">
            <el-radio-group v-model="form.type">
              <el-radio :label="1">覆盖</el-radio>
              <el-radio :label="2">新增</el-radio>
            </el-radio-group>
          </el-form-item>
        </template>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            drag
            :limit="1"
            action=""
            :headers="{ token: token }"
            :file-list="fileList"
            :on-remove="removeFile"
            :multiple="false"
            :http-request="httpRequestBack"
            accept=".xls,.xlsx"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="" prop="type" v-if="currImportType == 1">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">新增</el-radio>
            <el-radio :label="2">更新</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="uploadSubmit" :loading="form.loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 导入异常弹窗 -->
    <el-dialog
      :title="currImportType == 1 ? '导入商品反馈信息' : '更新分组价反馈信息'"
      :visible.sync="errorDialogVisible"
      width="500px"
      center
      :before-close="errorClose"
    >
      <div style="font-weight: bold">
        导入成功：{{ templateDialog.result }} 条
      </div>
      <div v-if="templateDialog.errors.length > 0" class="errorBox">
        <p v-for="(item, index) in templateDialog.errors" :key="index">
          {{ index + 1 }}、{{ item }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="errorExp">下载反馈信息</el-button>
        <el-button @click="errorClose">关闭</el-button>
      </span>
    </el-dialog>

    <div class="currDemo">
      <!-- 溯源码弹窗 -->
      <el-dialog
        title="查看溯源码"
        :visible.sync="dialogVisibleCode"
        width="450px"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <!-- <el-image v-if="!!imgUrl" :src="imgUrl"></el-image> -->
        <div ref="canvasImg" class="code__wrap">
          <div>
            商品名称：<span style="font-weight: bold">{{
              currRow.good_name
            }}</span>
          </div>
          <div class="mt15">溯源编码：{{ currRow.source_code }}</div>
          <dir
            style="
              background: #f5f5f5;
              padding: 25px;
              border-radius: 10px;
              bodrer: solid 1px #e5e5e5;
            "
          >
            <div class="qrCodeUre" ref="qrCodeUre"></div>
          </dir>
        </div>

        <div style="text-align: center; margin-top: 10px">
          <el-button plain :loading="loading" @click="handleCopy"
            >复制溯源码</el-button
          >
        </div>
      </el-dialog>
    </div>

    <!-- 修改供应商 （数据包含供应商/采购员） -->
    <el-dialog
      title="修改供应商"
      :visible.sync="dialogVisibleSupplier"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref="elFormDom1"
        label-width="100px"
        label-position="top"
        :model="supplierForm.data"
        :rules="supplierForm.rules"
        :size="subUnitSize"
      >
        <div v-if="multipleSelection.length > 0" style="display: block;text-align: center;font-size: 16px;font-weight: bold;">已选商品{{multipleSelection.length}}个</div>
        <el-form-item label="供应商/采购员" prop="supplier_id">
          <el-select
            v-model="supplierForm.data.supplier_id"
            filterable
            multiple
            style="width: 100%; margin: 10px 0"
          >
            <el-option
              v-for="item in supplierOpt"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <div style="float: right;">
                <span>({{item.province_str}}{{item.city_str}})</span>
                <span style="color: #8492a6; font-size: 13px;margin-left:10px;">{{
                  item.type == 1 ? "供应商" : "采购员"
                }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button
            type="primary"
            :loading="supplierForm.loading"
            :disabled="supplierForm.loading"
            @click="handleRemark"
            >确定</el-button
          >
        </el-row>
      </template>
    </el-dialog>

    <!-- 导出询价表-->
    <el-dialog
      title="导出询价表"
      :visible.sync="dialogVisibleGroup"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref="elFormDom"
        label-width="100px"
        label-position="top"
        :model="supplierForm.data"
        :rules="supplierForm.rules"
        :size="subUnitSize"
      >
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select
            v-model="supplierForm.data.merchant_id"
            filterable
            placeholder="请选择"
            clearable
            style="width: 100%; margin: 10px 0"
          >
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button
            type="primary"
            :loading="supplierForm.loading"
            :disabled="supplierForm.loading"
            @click="handleGroupExp"
            >确定</el-button
          >
        </el-row>
      </template>
    </el-dialog>

    <!-- 修改供应商失败提示 -->
    <el-dialog
      title="修改失败"
      :visible.sync="dialogVisibleError"
      width="600px"
      center
      :before-close="handleClose">
      <div >
        <div v-for="(item,index) in errorList" :key="index" style="margin-bottom:10px;">{{item}}</div>
      </div>
    </el-dialog>

    <!-- 新增、编辑 -->
    <edit
      ref="edit"
      @refresh="getTable"
      :supplierOpt="supplierArr"
      :merchantArr="merchantArr"
      :clientGroupArr="clientGroupArr"
    ></edit>
    <!-- 图片导入 -->
    <abc ref="abc" @refresh="getTable"></abc>
    <!-- 批量调价 -->
    <pri
      ref="pri"
      :merchantArr="merchantArr"
      :cateOpt="cateOpt"
      @refresh="getTable"
    ></pri>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import Edit from "./components/Edit.vue";
import Abc from "./components/Picture.vue";
import Pri from "./components/Price.vue";
import VTable from "@/components/VTable";
import { mixinTable } from "@/mixins/table.js";

export default {
  name: "",
  components: {
    Edit,
    VTable,
    Abc,
    Pri,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      multipleSelection: [],
      height: 0,
      isExpandAll: true,
      refreshTable: true,
      loading: false,

      table: {
        loading: false,
        params: {
          status: 1,
          good_name: "",
          date: "",
          publish_start: "",
          publish_end: "",
          cate_id: "",
          order_cate_arr: [],
          order_cate_id: "",
          order_cate_tow: "",
          group_id: "",
          merchant_id: '',
          bind_id: "", // 供应商/采购员id
          down_time: "",
          down_time_start: "",
          down_time_end: "",
          norm_id: "", // 标准名称
          is_norm: "", //是否绑定标名 1:是 2:否
          is_image: "", //是否有图片 1:是 2:否
          is_sold_out: false, // 1-售罄；0-有货(false)
          is_spec: false, // 1:未设置规格
          is_price: false, // 1:未设置报价
          is_top: "", // 是否置顶 1:是 0:否
          page: 1,
          count: 10,
        },
        data: [],
        total: 0,
        publish_total: 0,
        un_publish_total: 0,
      },
      dialogFormVisible: false,
      form: {
        loading: false,
        data: {
          name: "",
          good_name: "",
          norms: "",
          group: [],
        },
        rules: {
          group_id: [
            { required: true, message: "请选择客户分组", trigger: "change" },
          ],
        },
        group_id: "", // 更新分组价时选择客户分组
        type: 1, // 更新分组价时选择类型 1:覆盖 2:新增
      },
      cateOpt: [], // 商品分类
      groupOpt: [], // 售价分组
      spec_id: "", // 规格id
      supplierArr: [], // 供应商/采购员
      dialogVisible: false,
      currImportType: 0, // 1:导入商品，2：更新分组价
      fileList: [],
      // 导入后提示
      errorDialogVisible: false, // 导入异常弹窗
      templateDialog: {
        result: 0,
        errors: [],
      },

      groupsData: [],
      orderCateArr: [], // 下单分类
      merchantArr: [], // 客户列表
      norm_id: "",
      normArr: [], // 商品标名
      selLoading: false,

      dialogVisibleCode: false,
      currRow: {},
      imgUrl: "",
      codeTextUrl: "", // 溯源
      dialogVisibleSupplier: false,
      dialogVisibleGroup: false,
      supplierOpt: [],
      supplierForm: {
        loading: false,
        data: {
          id: "", // 商品id
          supplier_id: [], // 供应商id
          merchant_id: "",
        },
        rules: {
          merchant_id: [
            { required: true, message: "请选择供应商", trigger: "change" },
          ],
          group_id: [
            { required: true, message: "请选择客户分组", trigger: "change" },
          ],
        },
      },
      dialogVisibleError: false,
      errorList: [],
      clientGroupArr: [], // 客户分组
    };
  },
  created() {
    this.getTable();
    this.getCate();
    this.getGroup();
    this.getClientGroup(); // 客户分组
    this.getSupplierSel(); // 供应商/采购员
    this.getMerchantSel(); // 客户列表
    if (window.location.href.indexOf(".cuntoubao.test") != "-1") {
      this.codeTextUrl =
        "http://h5delivery.cuntoubao.test/#/pages/index/sourceinfo"; // 测式服地址
    } else {
      this.codeTextUrl =
        "https://h5delivery.cuntoubao.cn/#/pages/index/sourceinfo"; // 正式服地址
    }
  },
  mounted() {
    this.height = this.$refs.main.offsetHeight || 500;
  },
  methods: {
    getTable() {
      this.table.loading = true;
      let _params = { ...this.table.params };
      let _date = this.table.params.date;
      let _down = this.table.params.down_time;
      if (_date && _date.length > 0) {
        _params.publish_start = _date[0];
        _params.publish_end = _date[1];
      } else {
        _params.publish_start = "";
        _params.publish_end = "";
      }
      if (_down && _down.length > 0) {
        _params.down_time_start = _down[0];
        _params.down_time_end = _down[1];
      } else {
        _params.down_time_start = "";
        _params.down_time_end = "";
      }
      _params.is_sold_out = this.table.params.is_sold_out == true ? 1 : 0;
      _params.is_spec = this.table.params.is_spec == true ? 1 : "";
      _params.is_price = this.table.params.is_price == true ? 1 : "";
      delete _params.date;
      delete _params.down_time;
      delete _params.order_cate_arr;
      this.$http.post("/admin/goods/list", _params).then((res) => {
        if (res.code === 1) {
          this.table.loading = false;
          console.log(res.data.list, "55");
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.publish_total = res.data.publish_total;
          this.table.un_publish_total = res.data.un_publish_total;
        }
      });
    },
    // 重置查询条件
    reset() {
      this.table.params = {
        status: this.table.params.status,
        good_name: "",
        date: "",
        publish_start: "",
        publish_end: "",
        cate_id: "",
        order_cate_arr: [],
        order_cate_id: "", // 一级分类
        order_cate_tow: "", // 二级分类
        group_id: "",
        merchant_id: '',
        bind_id: "", // 供应商/采购员id
        down_time: "",
        down_time_start: "",
        down_time_end: "",
        is_norm: "",
        is_image: "",
        is_sold_out: false,
        is_spec: false,
        is_price: false,
        is_top: "", //是否置顶
      };
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.getTable();
    },
    // 获取分类
    getCate() {
      this.$http.post("/admin/cate/all", {}).then((res) => {
        if (res.code == 1) {
          this.cateOpt = res.data;
        }
      });
    },
    // 获取售价分组
    getGroup() {
      this.$http.post("/admin/group/all", {}).then((res) => {
        if (res.code == 1) {
          this.groupOpt = res.data;
        }
      });
    },
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true);
      dom.getDetail(row);
      dom = null;
    },
    // 下架
    view(row) {
      let str = row.status == 1 ? "下架" : "上架";
      this.$confirm(
        `${str}商品将会把该商品内所有规格全部${str}，请确认${str}吗？`,
        `请确认${str}操作?`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$http
            .post("/admin/goods/updateStatus", {
              id: row.id,
              status: row.status == 1 ? 2 : 1,
            })
            .then((res) => {
              if (res.code == 1) {
                this.$message.success("操作成功！");
                this.getTable();
              }
            });
        })
        .catch(() => {});
    },
    handleClick(row) {
      this.setConfirm();
      this.$message.success(row.id);
    },
    // 分组售价
    handleGroups(row, index) {
      this.dialogFormVisible = true;
      this.spec_id = row.spec[index].id; // 规格id
      this.groupsData = row.spec[index].group.filter(
        (item) => item.is_show != 0
      ); // is_show:0:不显示，1显示
      this.form.data.good_name = row.good_name;
      this.form.data.cate_name = row.cate_name;
    },
    // 批量下架、批量上架
    handleBatch() {
      let str = this.table.params.status == 1 ? "下架" : "上架";
      if (this.multipleSelection.length > 0) {
        this.$confirm(
          `${str}商品将会把该商品内所有规格全部${str}，请确认${str}吗？`,
          `请确认批量${str}操作?`,
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.batchFn();
          })
          .catch(() => {});
      } else {
        this.$message.warning(`请选择要${str}的商品！`);
      }
    },
    // 批量下架、批量上架方法
    batchFn() {
      let idarr = this.multipleSelection.map((v) => {
        return v.id;
      });
      let _ids = Array.from(new Set(idarr)).join("|"); // id去重

      this.$http
        .post("/admin/goods/batch", {
          ids: _ids,
          status: this.table.params.status == 1 ? 2 : 1,
        })
        .then((res) => {
          if (res.code == 1) {
            this.getTable();
            this.$message.success("操作成功！");
          }
        });
    },
    // 导入商品、更新分组价
    handleImport(type) {
      this.currImportType = type; // 1:导入商品，2：更新分组价
      this.dialogVisible = true;
    },
    // 下载样表
    handleDownload() {
      exportData({}, "/admin/goods/download");
    },
    httpRequestBack(file) {
      this.curfile = file.file;
    },
    // 导入
    uploadSubmit() {
      this.$refs.elFormDom.validate((valid) => {
        if (valid) {
          if (!!this.curfile) {
            this.uploadRequer();
          } else {
            this.$message.warning("请选择要导入的文件");
          }
        }
      });
    },
    // 导入请求
    uploadRequer() {
      let formData = new FormData();
      formData.append("file", this.curfile);
      formData.append("type", this.form.type);
      if (this.currImportType == 2) {
        formData.append("group_id", this.form.group_id);
      }
      let apiUrl =
        this.currImportType == 1
          ? "/admin/goods/import"
          : "/admin/goods/importGroup"; // 1:导入商品，2：更新分组价
      this.form.loading = true;
      this.$http
        .post(apiUrl, { formData, type: "upload" })
        .then((res) => {
          if (res.code == 1) {
            this.errorDialogVisible = true;
            if(res.data.errors || res.data.count) {
              this.templateDialog.errors = res.data.errors;
              this.templateDialog.result = res.data.count;
            }
          }
        })
        .finally(() => {
          this.form.loading = false;
          this.handleClose();
        });
    },
    // 关闭导入弹窗
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisibleCode = false;
      this.dialogVisibleSupplier = false;
      this.dialogVisibleGroup = false;
      this.dialogVisibleError = false;
      this.errorList = [];
      this.imgUrl = "";
      this.fileList = [];
      this.curfile = "";
      this.form.group_id = "";
    },
    errorClose() {
      this.errorDialogVisible = false;
      this.getTable();
    },
    removeFile() {
      this.fileList = [];
    },
    // 导入图片
    handleImportImg() {
      let dom = this.$refs.abc;
      dom.toggle(true);
      dom = null;
    },
    // 批量调价
    handlePrice() {
      let dom = this.$refs.pri;
      dom.toggle(true);
      dom.getDetail();
      dom = null;
    },
    // 全部导出
    exportDataAll(url) {
      let str = "";
      let obj = { ...this.table.params };
      let _date = this.table.params.date;
      let _down = this.table.params.down_time;
      if (_date && _date.length > 0) {
        obj.publish_start = _date[0];
        obj.publish_end = _date[1];
      } else {
        obj.publish_start = "";
        obj.publish_end = "";
      }
      if (_down && _down.length > 0) {
        obj.down_time_start = _down[0];
        obj.down_time_end = _down[1];
      } else {
        obj.down_time_start = "";
        obj.down_time_end = "";
      }
      obj.is_sold_out = this.table.params.is_sold_out == true ? 1 : 0;
      obj.is_spec = this.table.params.is_spec == true ? 1 : 0;
      obj.is_price = this.table.params.is_price == true ? 1 : 0;
      delete obj.date;
      delete obj.down_time;
      delete obj.order_cate_arr;
      Object.keys(obj).forEach((item) => {
        str += `&${item}=${obj[item]}`;
      });
      console.log(
        `${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`
      );
      window.open(
        `${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`
      );
    },
    // 批量导出
    plexportDataGoods(url) {
      if (this.multipleSelection.length) {
        let ids = this.multipleSelection
          .map((item) => {
            return item.id;
          })
          .join(",");
        let str = "";
        let obj = {
          ...this.table.params,
          ids,
        };
        Object.keys(obj).forEach((item) => {
          str += `&${item}=${obj[item]}`;
        });
        console.log(
          `${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`
        );
        window.open(
          `${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`
        );
      } else {
        this.$message.warning("至少勾选一条数据");
      }
    },
    handlegroup() {
      this.dialogVisibleGroup = true;
    },
    handleGroupExp() {
      this.table.params.merchant_id = this.supplierForm.data.merchant_id;
      if (!!this.table.params.merchant_id) {
        this.exportData("/admin/goods/exportGroup");
        this.dialogVisibleGroup = false;
        this.table.params.merchant_id = "";
        this.supplierForm.data.merchant_id = "";
      } else {
        this.$message.warning("请先选择客户名称");
      }
    },
    // 删除
    remove(row) {
      this.setConfirm(
        "删除商品将会把该商品内所有规格全部删除，确认删除吗?",
        { ids: row.id },
        "/admin/goods/delete"
      );
    },
    // 批量删除
    removeBatch() {
      if (this.multipleSelection.length > 0) {
        let idarr = this.multipleSelection.map((v) => {
          return v.id;
        });
        let _ids = Array.from(new Set(idarr)).join("|"); // id去重
        this.setConfirm(
          "删除商品将会把该商品内所有规格全部删除，确认删除吗?",
          { ids: _ids },
          "/admin/goods/delete"
        );
      } else {
        this.$message.warning("请至少选择一条数据！");
      }
    },
    errorExp() {
      const text = this.templateDialog.errors;
      const blob = new Blob([text], { type: "text/plain" });
      // 创建a标签元素
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "file.txt";
      // 添加到页面上进行点击下装
      document.body.appendChild(link);
      link.click();
      // 移除临时生成的a标签无素
      document.body.removeChild(link);
    },
    // 设置某行样式
    tableRowClassName({ row, rowIndex }) {
      if (row.spec) {
        return "success-row";
      }
      return "";
    },
    // 展开、收起规格
    toggleExpand() {
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
    // 删除规格
    handleDelSpec(item) {
      // 若有分组价给个提示，否则直接删除
      if (item.group.length > 0) {
        this.$confirm("该规格存在分组价，确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.delspecFun(item);
          })
          .catch(() => {});
      } else {
        this.delspecFun(item);
      }
    },
    // 删除规格方法
    delspecFun(item) {
      this.$http.post("/admin/goods/delSpec", { ids: item.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("操作成功");
          this.getTable();
        }
      });
    },
    // 标准名称获得焦点时先清空上次的搜索内容
    focusNocus() {
      this.normArr = [];
    },
    // 获取标准名称
    remoteMethod(query) {
      if (query !== "") {
        this.selLoading = true;
        this.$http
          .get("/admin/norm/list", {
            params: { page: 1, count: 1000, keyword: query },
          })
          .then((res) => {
            if (res.code === 1) {
              this.normArr = res.data.list;
            }
          })
          .finally(() => {
            this.selLoading = false;
          });
      } else {
        this.normArr = [];
      }
    },
    handleNorm(row, index) {
      if (!this.norm_id) {
        this.$message.warning("请选商品标名");
        return;
      }
      this.$http
        .post("/admin/norm/setGoods", {
          goods_id: row.id,
          norm_id: this.norm_id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.norm_id = "";
            this.$message.success("操作成功！");
            this.$refs[`popoverDom+${index}`].doClose();
            this.getTable();
          }
        });
    },

    async showCode(row) {
      this.dialogVisibleCode = true;
      this.currRow = row;
      // let texturl = 'https://h5delivery.cuntoubao.cn/#/pages/index/sourceinfo?source_code=SYM20240307005578'
      //生成溯源二维码，
      await this.$nextTick(() => {
        this.$refs["qrCodeUre"].innerHTML = ""; //生成二维码之前先清除上一次的
        new QRCode(this.$refs.qrCodeUre, {
          text: `${this.codeTextUrl}?source_code=${row.source_code}`,
          width: 200,
          height: 200,
          colorDark: "#000",
          colorLight: "#fff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
      // 溯源信息生成图片
      try {
        const canvas = await html2canvas(this.$refs.canvasImg); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
        const image = canvas.toDataURL(); // 将生成的canvas转换为DataURL格式
        this.imgUrl = image; // 可以将image自行存储
        this.loading = false;
      } catch (e) {
        throw new Error(e);
      }
    },
    // 复制溯源码图片
    async handleCopy() {
      try {
        const data = await fetch(this.imgUrl);
        const blob = await data.blob();
        // await navigator.clipboard.write([
        //   new window.ClipboardItem({
        //     [blob.type]: blob
        //   })
        // ])
        setTimeout(() => {
          navigator.clipboard.write([
            new ClipboardItem({
              "image/png": new Promise(async (resolve) => {
                // TOD0 处理你的blob数据
                resolve(new Blob([blob], { type: "image/png" }));
              }),
            }),
          ]);
        }, 0);

        this.$message.success("复制成功");
      } catch (err) {
        this.$message.warning("复制失败");
      }
    },
    // 供应商/采购员(status:1启用中的供应商)
    getSupplier() {
      this.$http
        .get("/admin/supplier/allList", {
          params: { status: 1, page: 1, count: 10000 },
        })
        .then((res) => {
          if (res.code == 1) {
            this.supplierOpt = res.data.list;
          }
        });
    },
    // 单条修改供应商
    async handlesupplier(row) {
      await this.getSupplier();
      this.multipleSelection = [];
      this.$refs.refTable.clearSelection(); // 用于多选表格，清空用户的选择
      this.dialogVisibleSupplier = true;
      this.supplierForm.data.id = row.id;
      this.supplierForm.data.supplier_id = !!row.bind_ids
        ? row.bind_ids.split(",").map(Number)
        : []; //供应商/采购员返显
    },
    // 修改供应商
    handleRemark() {
      this.$refs.elFormDom1.validate((valid) => {
        if (valid) {
          let ids = this.multipleSelection.map((item) => item.id).join(","); // 商品ID数组
          let _params = {
            id: !!this.supplierForm.data.id ? this.supplierForm.data.id : ids, // 商品id
            bind_ids: this.supplierForm.data.supplier_id.join(","), //供应商ID 多个逗号隔开
          };
          this.supplierForm.loading = true;
          this.$http
            .post("/admin/goods/updateSupplier", _params)
            .then((res) => {
              if (res.code == 1) {
                this.dialogVisibleSupplier = false;
                if(res.data.error.length>0) {
                  this.dialogVisibleError = true;
                  this.errorList = res.data.error;
                } else {
                  this.$message.success("操作成功！");
                }
                this.supplierForm.data.id = '';
                this.supplierForm.data.supplier_id = [];
                // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
                this.$refs.elFormDom1.resetFields();
                this.getTable(); // 更新列表
              }
            })
            .finally(() => {
              this.supplierForm.loading = false;
            });
        }
      });
    },
    handSoldout(row) {
      console.log(row);
      let _params = {
        spec_id: this.spec_id,
        group_id: row.group_id,
        sold_out: row.sold_out == 1 ? 0 : 1, // 1-售罄；0-有货
      };
      this.$http.post("/admin/goods/soldOutSpec", _params).then((res) => {
        if (res.code == 1) {
          this.$message.success("操作成功！");
          row.sold_out = row.sold_out == 1 ? 0 : 1;
          this.$set(this.groupsData, row);
        }
      });
    },
    // 是否置顶
    handleStatus(row) {
      this.$http
        .post("/admin/goods/top", { id: row.id })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success("操作成功！");
          } else {
            this.$message.error(res.msg);
          }
        })
        .finally(() => {
          this.getTable();
        });
    },
    // 批量修改供应商
    handlesupplierBatch() {
      if(this.multipleSelection.length > 0) {
        this.getSupplier();
        this.dialogVisibleSupplier = true;
      } else {
        this.$message.warning("请先选择需要修改供应商的数据")
      }
    },
    // 操作
    handleCommand(value) {
      if(value == 'allexport') { // 全部导出
        this.exportDataAll('/admin/goods/export')
      } else if(value == 'option') { // 批量下架/上架
        this.handleBatch();
      } else if(value == 'price') { // 批量调价
        this.handlePrice();
      } else if(value == 'enquiry') { // 导出询价表
        this.handlegroup();
      } else if(value == 'remover') { // 批量删除
        this.removeBatch()
      }
    },
  },
};
</script>
<style scope lang="scss">
.el-table .success-row {
  background: #f6fcf9;
  color: #595959;
  border: none;
}
.el-table .success-row ::v-deep .el-table--border .el-table__cell {
  border-right: none !important;
}
.mr20 {
  margin-right: 20px;
}
.goodsbox {
  width: 22%;
  display: inline-block;
}
.item-row {
  margin-top: 5px;
}
.errorBox {
  max-height: 500px;
  overflow-y: scroll;
}
.curTableWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 24px;
  background: #fff;
  flex: 1;
  overflow-y: scroll;
}
.curTableWrap::-webkit-scrollbar {
  width: 0px;
}
.curTableWrap::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 0px;
}
.pagination__wrap {
  margin: 10px 0;
  text-align: right;
  /* padding-right: 40px; */
}
</style>
<style scoped>
::v-deep .el-table__expanded-cell {
  padding: 0 !important;
}
.specwrap {
  /* height: 40px; */
  line-height: 40px;
}
.specitem {
  border-bottom: solid 1px #eee;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.specitem .item {
  border-right: solid 1px #eee;
  text-align: center;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.specitem .item:last-child {
  border-right: none;
}
.textBtn {
  color: #2cb167;
  cursor: pointer;
}
.textBtn:hover {
  text-decoration: underline;
}
.mt15 {
  margin-top: 15px;
}
.code__wrap {
  margin: 0 auto;
  width: 250px;
  text-align: left;
  font-size: 16px;
}
.qrCodeUre {
  display: inline-block;
}
.currDemo ::v-deep .el-dialog__header {
  border-bottom: solid 1px #eee;
}
.currDemo ::v-deep .el-dialog__title::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.currDemo ::v-deep .el-button {
  border: solid 1px #2cb167;
  color: #2cb167;
}
.currDemo ::v-deep .el-button:hover {
  background: #2cb167;
  color: #fff;
}
.sold_out {
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}
</style>